<template>
  <div id="main-content" class="main-content-normal" :class="{ 'main-content-bigger': $store.state.mainBig }">
    <div class="container-fluid">
      <!-- Page header section  -->
      <div class="block-header">
        <div class="row clearfix">
          <div class="col-lg-5">
            <select-ps-id-view @psIdChange="handlePsIdChange" v-model="psId" />
          </div>

          <div class="col-lg-7 home-header-tag">
            <el-tag type="light" v-if="plant.plantType != ''"><span class="font-strong">{{ $t("home.PlantType") }}</span>{{ getPsType(plant.plantType) }}</el-tag>
            <el-tag type="light" v-if="plant.plantCode != ''"><span class="font-strong">{{ $t("home.PlantCode") }}</span>{{ plant.plantCode }}</el-tag>
            <!-- <el-tag type="success">
              <span class="fa-layers fa-fw mr-1">
                <i class="fad fa-circle-notch fa-spin"></i>
                <i class="fad fa-check" data-fa-transform="shrink-8 up-0.5"></i>
              </span>
              <span>{{ $t("home.StationStatusSuccess") }}</span>
            </el-tag>
            <el-tag type="danger">
              <span class="fa-layers fa-fw mr-1">
                <i class="fad fa-circle-notch fa-spin"></i>
                <i class="fad fa-tools" data-fa-transform="shrink-8 up-0.5"></i>
              </span>
              <span>{{ $t("home.StationStatusErro") }}</span>
            </el-tag> -->
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="col-12">
          <div class="card theme-bg gradient HomeData">
            <div class="card-body">
              <div style="" class="OverSetting theme-bg gradient">
                <!-- 設定 -->
                <a @click="openDragger" href="javascript:void(0);" data-target=".bd-HomeScrollSetting-modal-xl" :title="$t('home.setUp')"><i class="fad fa-cog fa-spin fa-4x"></i></a>
              </div>
              <div class="row">
                <template v-for="item in census">
                  <!-- PV发电量 -->
                  <div class="col-lg-3 col-md-6" :key="randomKey(item.index)" v-if="item.name == 'pvDcdc' && item.isVisible == 1">
                    <div class="card">
                      <div class="card-body p-1">
                        <div :id="item.name" class=" census-list carousel vert slide" data-ride="carousel" data-interval="12000">
                          <div class="carousel-inner">
                            <template v-for="(childItem, index) in item.items">
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'dayTotal' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- PV发电量 日发电量-->
                                  <i class="fad fa-battery-bolt mr-1"></i><big> {{ $t("home.dayTotalPac") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-2">
                                    <i class="fad fa-calendar-day" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-battery-bolt" data-fa-transform="shrink-10 right-8.5 down-3"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['pvDcdc']['dayTotal']
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['pvDcdc']['dayTotal']
                                      ).val
                                    "></span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'monthTotal' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- PV发电量 月发电量-->
                                  <i class="fad fa-battery-bolt mr-1"></i><big> {{ $t("home.monthTotalPac") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-2">
                                    <i class="fad fa-calendar-alt" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-battery-bolt" data-fa-transform="shrink-10 right-8.5 down-3"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['pvDcdc']['monthTotal']
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['pvDcdc']['monthTotal']
                                      ).val
                                    "></span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'yearTotal' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- PV发电量 年发电量-->
                                  <i class="fad fa-battery-bolt mr-1"></i><big> {{ $t("home.yearTotalPac") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-2">
                                    <i class="fad fa-calendar" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-battery-bolt" data-fa-transform="shrink-11 right-8.5 down-3"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['pvDcdc']['yearTotal']
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['pvDcdc']['yearTotal']
                                      ).val
                                    "></span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'total' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- PV发电量 总发电量-->
                                  <i class="fad fa-battery-bolt mr-1"></i><big> {{ $t("home.totalPac") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-2">
                                    <i class="fad fa-calendar-check" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-battery-bolt" data-fa-transform="shrink-10 right-8.5 down-3"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(censusData['pvDcdc']['total'])
                                        .unit
                                    " v-html="
                                      unitTranf(censusData['pvDcdc']['total'])
                                        .val
                                    "></span>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 电量 -->
                  <div class="col-lg-3 col-md-6" :key="randomKey(item.index)" v-if="item.name == 'gridPcs' && item.isVisible == 1">
                    <div class="card">
                      <div class="card-body p-1">
                        <div :id="item.name" class="census-list carousel vert slide" data-ride="carousel" data-interval="12000">
                          <div class="carousel-inner">
                            <template v-for="(childItem, index) in item.items">
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'dayInTotal' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 电量 日充电量-->
                                  <i class="fad fa-bolt mr-1"></i><big> {{ $t("home.dayInPower") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-2">
                                    <i class="fad fa-calendar-day" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-bolt" data-fa-transform="shrink-10 right-6.5 down-2.5"></i>
                                    <i class="fad fa-plus" data-fa-transform="shrink-12 right-9.2 down-4.5"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['gridPcs']['dayInTotal']
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['gridPcs']['dayInTotal']
                                      ).val
                                    "></span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'dayOutTotal' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 电量 日放电量-->
                                  <i class="fad fa-bolt mr-1"></i><big> {{ $t("home.dayOutPower") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-2">
                                    <i class="fad fa-calendar-day" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-bolt" data-fa-transform="shrink-10 right-6.5 down-2.5"></i>
                                    <i class="fad fa-minus" data-fa-transform="shrink-12 right-9.2 down-4.5"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['gridPcs']['dayOutTotal']
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['gridPcs']['dayOutTotal']
                                      ).val
                                    "></span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'monthInTotal' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 电量 月充电量-->
                                  <i class="fad fa-bolt mr-1"></i><big> {{ $t("home.monthInPower") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-2">
                                    <i class="fad fa-calendar-alt" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-bolt" data-fa-transform="shrink-10 right-6.5 down-2.5"></i>
                                    <i class="fad fa-plus" data-fa-transform="shrink-12 right-9.2 down-4.5"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['gridPcs']['monthInTotal']
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['gridPcs']['monthInTotal']
                                      ).val
                                    "></span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'monthOutTotal' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 电量 月放电量-->
                                  <i class="fad fa-bolt mr-1"></i><big> {{ $t("home.monthOutPower") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-2">
                                    <i class="fad fa-calendar-alt" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-bolt" data-fa-transform="shrink-10 right-6.5 down-2.5"></i>
                                    <i class="fad fa-minus" data-fa-transform="shrink-12 right-9.2 down-4.5"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['gridPcs']['monthOutTotal']
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['gridPcs']['monthOutTotal']
                                      ).val
                                    "></span>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 报警 -->
                  <div class="col-lg-3 col-md-6" :key="randomKey(item.index)" v-if="item.name == 'alarm' && item.isVisible == 1">
                    <div class="card">
                      <div class="card-body p-1">
                        <div :id="item.name" class=" census-list carousel vert slide" data-ride="carousel" data-interval="12000">
                          <div class="carousel-inner">
                            <template v-for="(childItem, index) in item.items">
                              <!-- <div class="carousel-item" :key="randomKey(index)" v-if="childItem.name=='dayTotal'&&childItem.isVisible==1" :class="childItem['active']"> -->
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'failureNum' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 报警 故障-->
                                  <i class="fad fa-siren-on mr-1"></i><big> {{ $t("home.fault") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <i class="fad fa-tools mr-1" data-fa-transform="shrink-2"></i> --><span class="fault">{{censusData["alarm"]["failureNum"] || 0}} </span>
                                  <!-- {{censusData["alarm"]["failureNum"]}} -->
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'offlineNum' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 报警 离线-->
                                  <i class="fad fa-siren-on mr-1"></i><big> {{ $t("home.offLine") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <i class="fad fa-wifi-slash mr-1" data-fa-transform="shrink-3"></i> --><span class="">{{
                                      censusData["alarm"]["offlineNum"] || 0
                                    }}</span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'stopNum' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 报警 停机-->
                                  <i class="fad fa-siren-on mr-1"></i><big> {{ $t("home.shutDown") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <i class="fad fa-pause-circle mr-1" data-fa-transform="shrink-2"></i> --><span class="">{{ censusData["alarm"]["stopNum"] || 0 }}</span>
                                </div>
                              </div>

                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'abnormalNum' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 通讯异常-->
                                  <i class="fad fa-siren-on mr-1"></i><big> {{ $t("home.abnormal") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <i class="fad fa-pause-circle mr-1" data-fa-transform="shrink-2"></i> --><span class="">{{ censusData["alarm"]["abnormalNum"] || 0 }}</span>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 节能减排 -->
                  <div class="col-lg-3 col-md-6" :key="randomKey(item.index)" v-if="item.name == 'co2' && item.isVisible == 1">
                    <div class="card">
                      <div class="card-body p-1">
                        <div :id="item.name" class="census-list carousel vert slide" data-ride="carousel" data-interval="12000">
                          <div class="carousel-inner">
                            <template v-for="(childItem, index) in item.items">
                              <!-- <div class="carousel-item" :key="randomKey(index)" v-if="childItem.name=='dayTotal'&&childItem.isVisible==1" :class="childItem['active']"> -->
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'dayCo2' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 节能减排 本日减排-->
                                  <i class="fad fa-leaf mr-1"></i><big> {{ $t("home.dayReduce") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-1">
                                    <i class="fad fa-calendar-day" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-leaf" data-fa-transform="shrink-10.5 right-7 down-3"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['co2']['dayCo2'],
                                        'co2'
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['co2']['dayCo2'],
                                        'co2'
                                      ).val
                                    "></span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'monthCo2' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 节能减排 本月减排-->
                                  <i class="fad fa-leaf mr-1"></i><big> {{ $t("home.monthReduce") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-1">
                                    <i class="fad fa-calendar-alt" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-leaf" data-fa-transform="shrink-10.5 right-7 down-3"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['co2']['monthCo2'],
                                        'co2'
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['co2']['monthCo2'],
                                        'co2'
                                      ).val
                                    "></span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'yearCo2' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 节能减排 今年减排-->
                                  <i class="fad fa-leaf mr-1"></i><big> {{ $t("home.yearReduce") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-1">
                                    <i class="fad fa-calendar" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-leaf" data-fa-transform="shrink-10.5 right-7 down-3"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['co2']['yearCo2'],
                                        'co2'
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['co2']['yearCo2'],
                                        'co2'
                                      ).val
                                    "></span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'totalCo2' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 节能减排 总减排-->
                                  <i class="fad fa-leaf mr-1"></i><big> {{ $t("home.totalReduce") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-1">
                                    <i class="fad fa-calendar-check" data-fa-transform="shrink-2"></i>
                                    <i class="fad fa-leaf" data-fa-transform="shrink-10.5 right-7 down-3"></i>
                                  </span> -->
                                  <span :class="
                                      unitTranf(
                                        censusData['co2']['totalCo2'],
                                        'co2'
                                      ).unit
                                    " v-html="
                                      unitTranf(
                                        censusData['co2']['totalCo2'],
                                        'co2'
                                      ).val
                                    "></span>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 功率 -->
                  <div class="col-lg-3 col-md-6" :key="randomKey(item.index)" v-if="item.name == 'power' && item.isVisible == 1">
                    <div class="card">
                      <div class="card-body p-1">
                        <div :id="item.name" class="census-list carousel vert slide" data-ride="carousel" data-interval="12000">
                          <div class="carousel-inner">
                            <template v-for="(childItem, index) in item.items">
                              <!-- <div class="carousel-item" :key="randomKey(index)" v-if="childItem.name=='dayTotal'&&childItem.isVisible==1" :class="childItem['active']"> -->
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'dcdcPac' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 功率 PV功率-->
                                  <i class="fad fa-heart-rate"></i>
                                  <big> {{ $t("home.pvPower") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-2">
                                    <i class="fad fa-battery-bolt" data-fa-transform="shrink-3"></i>
                                    <i class="fad fa-analytics" data-fa-transform="shrink-10.5 right-8 down-3"></i>
                                  </span> -->
                                  <span class="kw">{{
                                    censusData["power"]["dcdcPac"]
                                  }}</span>
                                </div>
                              </div>
                              <div class="carousel-item" :key="randomKey(index)" v-if="
                                  childItem.name == 'pcsPac' &&
                                    childItem.isVisible == 1
                                " :class="childItem['active']">
                                <div>
                                  <!-- 功率 充放电功率-->
                                  <i class="fad fa-heart-rate"></i>
                                  <big> {{ $t("home.inOutPower") }}</big>
                                </div>
                                <div class="mt-3 h1">
                                  <!-- <span class="fa-layers fa-fw mr-1">
                                    <i class="fad fa-bolt" data-fa-transform="shrink-2.5"></i>
                                    <i class="fad fa-plus-circle" data-fa-transform="shrink-11.5 left-6.5 up-4"></i>
                                    <i class="fad fa-minus-circle" data-fa-transform="shrink-11.5 left-6.5 down-2"></i>
                                    <i class="fad fa-analytics" data-fa-transform="shrink-10.5 right-6 down-3"></i>
                                  </span> -->
                                  <span class="kw">{{
                                    censusData["power"]["pcsPac"]
                                  }}</span>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="col-xl-8 col-lg-7 col-md-12">
          <div class="card">
            <div class="header">
              <!-- <h2>{{ $t("home.system")}}</h2> -->
              <!-- <ul class="header-dropdown">
                <li>
                  <a href="javascript:void(0);" @click="FullScreen(!isFullScreen)" class="full-screen" v-bind:class="
                      isFullScreen ? 'full-screen-open' : 'full-screen-close'
                    ">
                    <i class="fad fa-expand-alt icon-expend"></i>
                    <i class="fad fa-compress-alt icon-compress"></i>
                  </a>
                </li>
              </ul> -->
            </div>
            <div class="body" style="margin-right:50px;margin-left:50px;">
              <div class="col-md-12 text-center" id="canvasDiv" style="height:460px;">
                <!-- <div id="" > -->
                <!-- <img src="../assets/images/F4/F4.svg" class="img-fluid" width="800" /> -->

                <!-- </div>  -->
                <canvas-img :key="timmer" :height="460" :width="canvasWidth" :dataResult="soipData" :psTypeId="$store.state.psTypeId"></canvas-img>

              </div>

              <div class="col-md-12 text-right"><span>{{ $t("home.lastDateTime") }}</span> ：<span>{{lastDateTime}}</span></div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-5 col-md-12">
          <div class="card">
            <div class="header">
              <h2>{{ $t("home.systemStatus") }}</h2>
              <ul class="header-dropdown">
                <!-- <li>
                  <a href="javascript:void(0);" @click="FullScreen(!isFullScreen)" class="full-screen" v-bind:class="
                      isFullScreen ? 'full-screen-open' : 'full-screen-close'
                    ">
                    <i class="fad fa-expand-alt icon-expend"></i>
                    <i class="fad fa-compress-alt icon-compress"></i>
                  </a>
                </li> -->
                <li class="dropdown">
                  <a @click="openDragger2" href="javascript:void(0);" class="dropdown-toggle" data-target=".bd-F4Setting-modal-xl"><i class="fad fa-cog fa-spin"></i></a>
                </li>
              </ul>
            </div>
            <div class="body">
              <div class="row clearfix">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-hover mb-0 home-table">
                      <!-- {"name":"storage","isVisible":1,
                      "items":[
                      {"type":"c","name":"storage","isVisible":1},
                      {"type":"n","name":"storage","isVisible":1},
                      {"type":"d","name":"pac","isVisible":"1"},
                      {"type":"d","name":"bv","isVisible":"1"},
                      {"type":"d","name":"soc","isVisible":"1"},
                      {"type":"d","name":"dayOutTotal","isVisible":"1"},
                      {"type":"d","name":"dayInTotal","isVisible":"1"},
                      {"type":"d","name":"devicePower","isVisible":"1"}
                      ]} -->
                      <tbody>
                        <template v-for="item in soip">
                          <tr :key="randomKey(item.index)" v-if="item.name == 'storage' && item.isVisible == 1">
                            <td class="text-center">
                              <i class="fad fa-car-battery fa-2x text-violet"></i>
                            </td>
                            <td>
                              <!-- 储能 -->
                              <a href="#" title=""><big>{{ $t("home.energyStorage") }}</big></a>
                              <!-- 充电/放电 -->
                              <!-- <p class="mb-0">
                                {{ $t("home.inOutElectricity") }}
                              </p> -->
                            </td>
                            <template v-for="childItem in item.items">
                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'bcp' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="kw"><big>{{
                                      soipData["storage"]["bcp"] || "-"
                                    }}</big></span>
                                </div>
                                <!-- 充/放电功率 -->
                                <small class="font-12 text-muted">{{
                                  $t("home.inOutPower")
                                }}</small>
                              </td>

                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'discharge' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="kwh"><big>{{
                                      soipData["storage"]["discharge"] || "-"
                                    }}</big></span>
                                </div>
                                <!-- 剩余电量 -->
                                <small class="font-12 text-muted">{{
                                  $t("home.remainingPower")
                                }}</small>
                              </td>

                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'devicePower' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="kwh"><big>{{
                                      soipData["storage"]["devicePower"] || "-"
                                    }}</big></span>
                                </div>
                                <!-- 电池容量 -->
                                <small class="font-12 text-muted">{{
                                  $t("home.batteryCapacity")
                                }}</small>
                              </td>

                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'soc' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="percent"><big>{{
                                      soipData["storage"]["soc"] || "-"
                                    }}</big></span>
                                </div>
                                <small class="font-12 text-muted">SOC</small>
                              </td>
                            </template>
                          </tr>
                          <tr :key="randomKey(item.index)" v-if="item.name == 'outPut' && item.isVisible == 1">
                            <td class="text-center">
                              <i class="fad fa-solar-panel fa-2x text-sea"></i>
                            </td>
                            <td>
                              <!-- 发电 -->
                              <a href="#" title=""><big>{{ $t("home.electricPower") }}</big></a>
                              <!-- 状态 -->
                              <!-- <p class="mb-0">{{ $t("home.state") }}</p> -->
                            </td>
                            <template v-for="childItem in item.items">
                              <!-- <td :key="soipkey(childItem.name)" v-if="childItem.name=='bcp'&&item.isVisible==1"> -->
                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'pac' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="kw"><big>{{
                                      soipData["outPut"]["pac"] || "-"
                                    }}</big></span>
                                </div>
                                <!-- 实时功率 -->
                                <small class="font-12 text-muted">{{
                                  $t("home.realtimePower")
                                }}</small>
                              </td>

                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'devicePower' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="kw"><big>{{
                                      soipData["outPut"]["devicePower"] || "-"
                                    }}</big></span>
                                </div>
                                <!-- DC/DC容量 -->
                                <small class="font-12 text-muted">{{
                                  $t("home.dcdcCapacity")
                                }}</small>
                              </td>

                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'dayPVTotal' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="kwh"><big>{{
                                      soipData["outPut"]["dayPVTotal"] || "-"
                                    }}</big></span>
                                </div>
                                <!-- DC/DC容量 -->
                                <small class="font-12 text-muted">{{
                                  $t("home.dayPVTotal")
                                }}</small>
                              </td>
                            </template>
                            <td></td>
                          </tr>
                          <tr :key="randomKey(item.index)" v-if="item.name == 'pGrid' && item.isVisible == 1">
                            <td class="text-center">
                              <i class="fad fa-atom-alt fa-2x text-success"></i>
                            </td>
                            <td>
                              <!-- 电网 -->
                              <a href="#" title=""><big>{{ $t("home.powerGrid") }}</big></a>
                              <!-- 买电/卖电 -->
                              <!-- <p class="mb-0">{{ $t("home.gridSendTotal") }}</p> -->
                            </td>
                            <template v-for="childItem in item.items">
                              <!-- <tem :key="soipkey(childItem.name)" v-if="childItem.name=='bcp'&&item.isVisible==1"> -->
                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'pac' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="kw">
                                    <big>
                                      <!-- 自己消费 取pcs -->
                                      <span v-if="$store.state.psTypeId == 1 || $store.state.psTypeId == 3">{{
                                        soipData["pcs"]["pac"] || "-"
                                      }}</span>
                                      <!-- 过积载 先取电表没有再取pcs -->
                                      <span v-if="$store.state.psTypeId == 2">{{
                                        soipData["pGrid"]["pac"] ||
                                          soipData["pcs"]["pac"] ||
                                          "-"
                                      }}</span>
                                    </big>
                                  </span>
                                </div>
                                <!-- 买/卖电功率 -->
                                <small class="font-12 text-muted">{{
                                  $t("home.realtimePower")
                                }}</small>
                              </td>

                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'inOutStatus' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <!-- 自己消费 取pcs -->
                                  <span v-if="$store.state.psTypeId == 1 || $store.state.psTypeId == 3" class="kw"><big>{{
                                      soipData["pcs"]["pac"] || "-"
                                    }}</big></span>
                                  <!-- 过积载 先取电表没有再取pcs -->
                                  <span v-if="$store.state.psTypeId == 2" class="kw"><big>{{
                                      soipData["pcs"]["pac"] ||
                                        soipData["pGrid"]["pac"] ||
                                        "-"
                                    }}</big></span>
                                </div>
                                <!-- 买/卖电状态 -->
                                <small class="font-12 text-muted">
                                  <!-- {{ soipData["pGrid"]["inOutStatus"] == 0 ? "-" : soipData["pGrid"]["inOutStatus"] == 1 ? $t("home.grid"): $t("home.send")}} -->
                                  {{ $t("home.realtimePower")}}
                                </small>
                              </td>

                              <!-- 买电量 -->
                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'dayGridEngery' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <!-- 自己消费 取pcs -->
                                  <span v-if="$store.state.psTypeId == 1 || $store.state.psTypeId == 3" class="kwh"><big>{{
                                      soipData["pcs"]["dayInTotal"] || "-"
                                    }}</big></span>
                                  <!-- 过积载 先取电表没有再取pcs -->
                                  <span v-if="$store.state.psTypeId == 2" class="kwh"><big>{{
                                       soipData["pGrid"]["dayGridEngery"] || soipData["pcs"]["dayInTotal"] || "-" 
                                    }}</big></span>
                                </div>

                                <small class="font-12 text-muted">{{
                                  $t("home.dayGridEngery")
                                }}</small>
                              </td>

                              <!-- 卖电量 -->
                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'daySendEngery' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <!-- 自己消费 取pcs -->
                                  <span v-if="$store.state.psTypeId == 1 || $store.state.psTypeId == 3" class="kwh"><big>{{
                                      soipData["pcs"]["dayOutTotal"] || "-"
                                    }}</big></span>
                                  <!-- 过积载 先取电表没有再取pcs -->
                                  <span v-if="$store.state.psTypeId == 2" class="kwh"><big>{{
                                      soipData["pGrid"]["daySendEngery"]  || soipData["pcs"]["dayOutTotal"] || "-"
                                    }}</big></span>
                                </div>
                                <!-- 卖电量 -->
                                <small class="font-12 text-muted">{{
                                  $t("home.daySendEngery")
                                }}</small>
                              </td>

                            </template>
                            <td></td>
                          </tr>
                          <tr :key="randomKey(item.index)" v-if="
                              item.name == 'inPut' &&
                                item.isVisible == 1 &&
                                ($store.state.psTypeId == 1 || $store.state.psTypeId == 3)
                            ">
                            <td class="text-center">
                              <i class="fad fa-outlet fa-2x text-warning"></i>
                            </td>
                            <td>
                              <!-- 负载 -->
                              <a href="#" title=""><big>{{ $t("home.load") }}</big></a>
                              <!-- <p class="mb-0">
                                <i class="fad fa-solar-panel"></i>
                                <i class="fad fa-atom-alt"></i>
                              </p> -->
                            </td>
                            <template v-for="childItem in item.items">
                              <!-- <tem :key="soipkey(childItem.name)" v-if="childItem.name=='bcp'&&item.isVisible==1"> -->
                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'pac' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="kw"><big>{{
                                   soipData["inPut"]["pac"] != null?
                                   zeroHandle(parseFloat(soipData["inPut"]["pac"]))
                                   :"-"
                                    }}</big></span>
                                </div>
                                <!-- 实时消耗功率 -->
                                <small class="font-12 text-muted">{{
                                  $t("home.realtimePowerUseUp")
                                }}</small>
                              </td>

                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'dayTotal' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="kwh"><big>{{
                                      soipData["inPut"]["dayTotal"] || "-"
                                    }}</big></span>
                                </div>
                                <!-- 今日消耗电量 -->
                                <small class="font-12 text-muted">{{
                                  $t("home.dayPowerUseUp")
                                }}</small>
                              </td>
                            </template>
                            <td></td>
                          </tr>
                          <tr :key="randomKey(item.index)" v-if="item.name == 'ammeter' && item.isVisible == 1">
                            <td class="text-center">
                              <i class="fad fa-tasks fa-2x text-success"></i>
                            </td>
                            <td>
                              <!-- 用户电表 -->
                              <a href="#" title=""><big>{{
                                  $t("home.userAmmeter.title")
                                }}</big></a>
                            </td>
                            <template v-for="childItem in item.items">
                              <!-- <tem :key="soipkey(childItem.name)" v-if="childItem.name=='bcp'&&item.isVisible==1"> -->
                              <td :key="soipkey(childItem.name)" colspan="3">
                                <div v-if="
                                  childItem.name == 'power' &&
                                    childItem.isVisible == 1
                                ">
                                  <div>
                                    <span class="kw"><big>
                                        <span>{{
                                        soipData["pGrid"]["pac"] || "-"
                                      }}</span>
                                      </big></span>
                                  </div>
                                  <!-- 买/卖电功率 -->
                                  <small class="font-12 text-muted">{{
                                  $t("home.realtimePower")
                                }}</small>

                                </div>
                              </td>
                              <!-- 买卖电量数据没有暂时不做 -->
                              <!-- <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'dayGridEngery' &&
                                    childItem.isVisible == 1
                                ">
                                <div>

                                  <span class="kwh"><big>{{
                                      soipData["pGrid"]["dayGridEngery"] || "-"
                                    }}</big></span>

                                </div>
                            
                                <small class="font-12 text-muted">{{
                                  $t("home.dayGridEngery")
                                }}</small>
                              </td>

                              <td :key="soipkey(childItem.name)" v-if="
                                  childItem.name == 'daySendEngery' &&
                                    childItem.isVisible == 1
                                ">
                                <div>
                                  <span class="kwh"><big>{{
                                      soipData["pGrid"]["daySendEngery"] || "-"
                                    }}</big></span>

                                </div>
                            
                                <small class="font-12 text-muted">{{
                                  $t("home.daySendEngery")
                                }}</small>
                              </td> -->
                            </template>
                            <td></td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 6个设备板块 -->
          <div class="row clearfix">
            <ul id="cardArea" class="card-area w-100 m-0" :class="
                deviceGroup.length > 0 ? 'card-area-' + deviceGroup.length : ''
              ">
              <template v-for="(item, index) in deviceGroup">
                <!-- 蓄电池 -->
                <li class="card-item" :class="index == active ? 'active' : ''" @mouseover="seover(index)" @mouseleave="seleave(index)" :key="item.deviceTypeId + 'abcd'">
                  <div class="card">
                    <div class="card-mini">
                      <a href="javascript:void(0);" @click="setParent(item.deviceTypeId)" :title="$t(deviceTypeName[item.deviceTypeId])">
                        <div class="card-title title-even">
                          <div class="content">
                            <div class="zq-product-img" :class="deviceTypeColor[item.deviceTypeId]">
                              <i :class="deviceTypeIcon[item.deviceTypeId]" class="fa-2x"></i>
                            </div>
                            <p>{{ $t(deviceTypeName[item.deviceTypeId]) }}</p>
                          </div>
                        </div>
                        <div class="card-content content-first">
                          <p>{{ item.devcies.length }}</p>
                        </div>
                      </a>
                    </div>
                    <div class="card-content content-second">
                      <div class="Six-Bignumber">
                        <a href="javascript:void(0);" @click="setParent(item.deviceTypeId)" :title="$t(deviceTypeName[item.deviceTypeId])" class="w-100">
                          <h1>{{ item.devcies.length }}</h1>
                        </a>
                      </div>
                      <div class="col-12 p-0">
                        <!-- 查看曲线图 -->
                        <!-- <a href="/deviceAnalyse" target="_blank" class="btn btn-primary theme-bg gradient btn-round btn-sm col-12" :title="$t('home.viewGraph')"><i class="fad fa-analytics"></i></a> -->
                        <router-link to="/deviceAnalyse" class="btn btn-primary theme-bg gradient btn-round btn-sm col-12"><i class="fad fa-analytics"></i></router-link>
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectPsIdView from "../components/HistoryView/SelectPsId.vue";
import tableDragger from "../../node_modules/table-dragger/dist/table-dragger";
import canvasImg from "../components/Master/Canvas3.vue";
console.log(tableDragger);

export default {
  components: { SelectPsIdView, canvasImg },
  name: "Home",
  data() {
    return {
      lastDateTime:null,
      timmer: 0,
      canvasHeight: 0,
      canvasWidth: 0,
      isFullScreen: true,
      //选中的电站id
      psId: null,
      //电站列表
      psList: [],
      // 默认是否要弹出认领电站
      bindShow: false,
      deviceGroup: [],
      deviceTypeIcon01: {
        "1": "fad fa-archive", //PCS
        "3": "fad fa-car-battery", //蓄電池
        "2": "fad fad fa-tablet-rugged", //DCDC
        "5": "fad fad fa-tachometer-alt-fast", //电表
        "4": "fad fa-snow-blowing", //空调
        "6": "fad fa-fire-extinguisher" //消防
      },
      deviceTypeIcon: {},
      deviceColor: {},
      deviceTypeName: {
        "1": "deviceIcon.pcs", //"PCS",//PCS
        "2": "deviceIcon.dcdc", //"DCDC",//DCDC
        "3": "deviceIcon.bms", //"蓄電池",//蓄電池
        "5": "deviceIcon.ammeter", //"电表",//电表
        "4": "deviceIcon.ari", //"空调",//空调
        "6": "deviceIcon.fire" //"消防",//消防
      },
      // deviceTypeName: {
      //   "1": this.$t("deviceIcon.pcs"),//"PCS", //PCS
      //   "2": this.$t("deviceIcon.dcdc"),//"DCDC", //DCDC
      //   "3": this.$t("deviceIcon.bms"),//"蓄電池", //蓄電池
      //   "5": this.$t("deviceIcon.ammeter"),//"电表", //电表
      //   "4": this.$t("deviceIcon.ari"),//"空调", //空调
      //   "6": this.$t("deviceIcon.fire"),//"消防" //消防
      // },
      active: 0,
      //首页电站类型和电站ID
      plant: {
        plantType: "", // 2种电站类型 语言包索引"TypeSelf"和"TypeLoad"
        plantCode: "" //电站ID
      },
      //首页统计模块中显示的数据
      censusData: {
        psInfo: {
          psId: null,
          psName: null,
          psCapacity: null,
          psTypeId: null,
          psAddress: null,
          psTimezone: null,
          longitude: null,
          psBuildate: null,
          latitude: null
        },
        alarm: {
          failureNum: "-",
          offlineNum: "-",
          stopNum: "-"
        },
        pvDcdc: {
          dayTotal: "-",
          monthTotal: "-",
          yearTotal: "-",
          total: "-"
        },
        gridPcs: {
          dayInTotal: "-",
          monthInTotal: "-",
          dayOutTotal: "-",
          monthOutTotal: "-"
        },
        co2: {
          dayCo2: "-",
          monthCo2: "-",
          yearCo2: "-",
          totalCo2: "-"
        },

        power: {
          dcdcPac: "-",
          pcsPac: "-"
        }
      },
      //首页统计模块显示参数数据
      census: [
        {
          name: "alarm",
          isVisible: "1",
          interval: "100",
          index: "1",
          items: [
            { name: "failureNum", isVisible: "1", active: "active" },
            { name: "offlineNum", isVisible: "1" },
            { name: "stopNum", isVisible: "1" }
          ]
        },
        {
          name: "pvDcdc",
          isVisible: "1",
          interval: "100",
          index: "2",
          items: [
            { name: "dayTotal", isVisible: "1", active: "active" },
            { name: "monthTotal", isVisible: "1" },
            { name: "yearTotal", isVisible: "1" },
            { name: "total", isVisible: "1" }
          ]
        },
        {
          name: "gridPcs",
          isVisible: "1",
          interval: "100",
          index: "3",
          items: [
            { name: "dayInTotal", isVisible: "1", active: "active" },
            { name: "monthInTotal", isVisible: "1" },
            { name: "dayOutTotal", isVisible: "1" },
            { name: "monthOutTotal", isVisible: "1" }
          ]
        },
        {
          name: "co2",
          isVisible: "1",
          interval: "100",
          index: "4",
          items: [
            { name: "dayCo2", isVisible: "1" },
            { name: "monthCo2", isVisible: "1" },
            { name: "yearCo2", isVisible: "1", active: "active" },
            { name: "totalCo2", isVisible: "1" }
          ]
        },
        {
          name: "power",
          isVisible: "1",
          interval: "100",
          index: "5",
          items: [
            { name: "dcdcPac", isVisible: "1" },
            { name: "pcsPac", isVisible: "1" }
          ]
        }
      ],
      soip: [],
      soipSetting: [],

      soipData: {
        storage: {
          bv: "-",
          soc: "-",
          bcp: "-",
          dayInTotal: "-",
          dayOutTotal: "-",
          devicePower: "-"
        },
        outPut: { pac: "-", dayTotal: "-" },
        inPut: { pac: "-", dayTotal: "-" },
        pGrid: { pac: "-", gridTotal: "-", sendTotal: "-", inOutStatus: "" },
        pcs: { pac: "-", dayOutTotal: "-", dayInTotal: "-" }
      },

      // 第一次点击统计模块设置时，添加拖动效果
      dragger: true
    };
  },
  props: ["loadingPsInfo"],
  watch: {
    loadingPsInfo: {
      handler(loadingPsInfo) {
        if (loadingPsInfo) {
          this.getPsInfos();
        }
      }
    },
    //当获取模块参数发生变化时
    census(v1, v2) {
      console.log("统计模块参数发生变化，加载模块数据");
      this.getPsData();
    },
    //当获取模块参数发生变化时
    soip(v1, v2) {
      console.log("储、发、并、负参数发生变化");
      this.getPsSoipDatas();
    },

    //当选中的电站id值发生变化时重新加载
    psId(v, o) {
      $(".census-list").carousel("pause");
      this.$store.state.psId = v;
      console.log("变化后" + this.$store.state.psId);
      console.log("电站id发生变化...加载模块信息、加载设备信息");

      this.getPsDataSetting();
      //$('.census-list').carousel()
      this.getPsSoipSettings();

      this.getDeviceGroup();
      //电站id发生变化
      this.setPsId();

      if (this.$store.state.psId != null && this.$store.state.psId != "-1") {
        clearInterval(this.timer);
        this.timer = setInterval(this.refreshPage, 1000 * 60 * 1);
        // alert($("#canvasDiv").height());
      }
    },
    "$store.state.languageId": function() {
      //你需要执行的代码
      this.timmer = new Date().getTime(); //重新加载组件
    },
    "$store.state.psTypeId": function() {
      this.timmer = new Date().getTime(); //重新加载组件
    }
  },
  created() {
    //  if(this.$store.state.psId!=null&&this.$store.state.psId!='-1'){
    //   this.timer = setInterval(this.refreshPage, 1000 * 60 * 1);
    //   // alert($("#canvasDiv").height());

    //   }
    this.canvasHeight = $("#canvasDiv").height();
    this.canvasWidth = $("#canvasDiv").width();
  },
  mounted() {
    var _this = this;
    var elementResizeDetectorMaker = require("element-resize-detector"); //导入
    // 创建实例
    var erd = elementResizeDetectorMaker();
    // 创建实例带参
    var erdUltraFast = elementResizeDetectorMaker({
      strategy: "scroll", //<- For ultra performance.
      callOnAdd: true,
      debug: true
    });
    //监听canvas窗口大小变动后重新加载组件
    erd.listenTo(document.getElementById("canvasDiv"), function(element) {
      _this.canvasWidth = element.offsetWidth;
      _this.canvasHeight = element.offsetHeight;
      _this.timmer = new Date().getTime(); //重新加载组件
    });
    //alert( "NO.1"+JSON.stringify(this.census))
    //this.getDeviceGroup();
  },
  beforeDestroy() {
    //离开页面销毁定时器
    clearInterval(this.timer);
    this.timer = null;
  },
  // props:{
  //   isMenuSon:{type:Boolean},
  //   isMenuParent:{type:Boolean},
  // },
  methods: {
    getPsType(typeId){
      var psName="";
        switch(typeId){
          case 1:
              psName=this.$t("home.TypeSelf");
            break;
          case 2:
              psName=this.$t("home.TypeLoad");
            break;
          case 3:
              psName=this.$t("home.TypePeak");
            break;
          case 4:
              psName=this.$t("home.TypePpv");
            break;  
        }
        return  psName
    },
    //页面定时刷新
    refreshPage() {
      //this.getPsInfos();
      this.getDeviceGroup();

      this.getPsData();
      //this.getPsSoipSettings();
      //this.getPsDataSetting();
    },
    //发电量单位转换
    //全局发电量超过1000KW的话，需要进阶为1MW 即（1000KW=1MW 例如：19983.44KW 显示为19WM）
    unitTranf(value, type) {
      var result;
      switch (type) {
        case "co2":
          result = {
            val: "0",
            unit: "kg"
          };
          if (value == null || value == "-") {
          } else if (value > 1000000) {
            //千吨
            result.val = (value / 1000000).toFixed(0);
            result.unit = "kt";
          } else if (value > 1000) {
            //吨
            result.val = (value / 1000).toFixed(0);
            result.unit = "t";
          } else {
            result.val = value.toFixed(0);
            result.unit = "kg";
          }
          break;
        default:
          result = {
            val: "0",
            unit: "kwh"
          };
          if (value == null || value == "-") {
          } else if (value > 1000000) {
            result.val = (value / 1000000).toFixed(0);
            result.unit = "gwh";
          } else if (value > 1000) {
            result.val = (value / 1000).toFixed(0);
            result.unit = "mwh";
          } else {
            result.val = value.toFixed(0);
            result.unit = "kwh";
          }

          break;
      }
      return result;
    },

    randomKey(index) {
      var randomNum = ("000000" + Math.floor(Math.random() * 999999)).slice(-6);
      return index + "_" + randomNum;
    },

    soipkey(name) {
      var randomNum = ("000000" + Math.floor(Math.random() * 999999)).slice(-6);
      return name + "_" + randomNum;
    },
    FullScreen: function(isFullScreen) {
      this.isFullScreen = isFullScreen;
    },

    c1() {
      alert(323);
    },

    //电站切换
    handlePsIdChange(psBuilddate, psId,psName,psListDateTime) {
      this.deviceId = null;
      
      this.$store.state.buildDate = this.buildDate;

      this.psId = psId;
      this.$store.state.psId = this.psId;
      if(psListDateTime != null){
           this.lastDateTime = psListDateTime
      }else{
         this.lastDateTime = "- -"
      }
      this.soipData = {
        storage: {
          bv: "-",
          soc: "-",
          bcp: "-",
          dayInTotal: "-",
          dayOutTotal: "-",
          devicePower: "-"
        },
        outPut: { pac: "-", dayTotal: "-" },
        inPut: { pac: "-", dayTotal: "-" },
        pGrid: { pac: "-", gridTotal: "-", sendTotal: "-", inOutStatus: "" },
        pcs: { pac: "-", dayOutTotal: "-", dayInTotal: "-" }
      };
    },
    changePsId() {
      this.soipData = {
        storage: {
          bv: "-",
          soc: "-",
          bcp: "-",
          dayInTotal: "-",
          dayOutTotal: "-",
          devicePower: "-"
        },
        outPut: { pac: "-", dayTotal: "-" },
        inPut: { pac: "-", dayTotal: "-" },
        pGrid: { pac: "-", gridTotal: "-", sendTotal: "-", inOutStatus: "" },
        pcs: { pac: "-", dayOutTotal: "-", dayInTotal: "-" }
      };
      //alert()
      this.$store.state.psId = this.psId;
    },
    /**将子模块数据传给父模块，选中点击设备类型图标时传入此设备类型id */
    setParent: function(deviceTypeId) {
      //子组件中添加自定义事件，父组件调用时在引用子组件时添加自定义方法
      this.$emit("changeParent", deviceTypeId);
    },
    setPsId() {
      this.$emit("parentPsId");
    },
    /**将统计数据模块显示信息传给父组件 */
    setCensus() {
      //子组件中添加自定义事件，父组件调用时在引用子组件时添加自定义方法，将快捷设备类
      this.$emit("census", this.census);
    },
    /**将统计数据模块显示信息传给父组件 */
    setSoip() {
      //子组件中添加自定义事件，父组件调用时在引用子组件时添加自定义方法，将快捷设备类
      this.$emit("soip", this.soipSetting);
    },

    bindPsInfo() {
      this.$emit("bindPsInfo", this.bindShow);
    },
    //获取电站列表
    getPsInfos() {
      //psList
      let homeVue = this;
      const userId = window.sessionStorage.getItem("userId");
      if (userId != null)
        this.$http
          .get("/service-psinfo/psinfo/getPsInfoByUserId?userId=" + userId, {})
          .then(function(ret) {
            let data = ret.data;
            if (data.status == 200) {
              homeVue.psList = data.data;
              if (homeVue.psList.length == 0) {
                homeVue.bindShow = true;
                homeVue.bindPsInfo();
              }

              if (
                !(typeof homeVue.psList === "undefined") &&
                homeVue.psList.length > 0
              ) {
                if (
                  homeVue.$store.state.psId != undefined &&
                  homeVue.$store.state.psId != ""
                ) {
                  homeVue.psId = homeVue.$store.state.psId;
                } else {
                  homeVue.psId = homeVue.psList[0]["psId"];
                  homeVue.$store.state.psId = homeVue.psList[0]["psId"];
                }
              }
              //swal(data.msg, "保存成功", "success");
            } else {
              //swal(data.msg, "保存失败", "warning");
            }
          })
          .catch(function() {});
    },

    ///psdata/getPsSoipDatas
    async getPsSoipSettings() {
      let homeVue = this;
      if (
        homeVue.$store.state.psId != undefined &&
        homeVue.$store.state.psId != ""
      ) {
        this.psId = homeVue.$store.state.psId;
      }
      const { data: ret } = await this.$http.get(
        "/service-psinfo/psdata/getPsSoipSettings?psId=" + this.psId,
        {}
      );
      if (ret.status == 200) {
        this.soip = [];
        let data = ret.data;
        this.soipSetting = data;
        // var items =this.soipSetting;
        // console.log("soip:" + JSON.stringify(this.soipSetting));
        // if(this.$store.state.psTypeId==2){
        //     items.splice(1,1)
        //     this.soipSetting=items;
        // }
        //console.log("aaaaaaaaaa",this.soipSetting)
        for (let i = 0; i < data.length; i++) {
          //  let row={"name":data[i]["name"],"isVisible":1,"items":[]};
          //  let column=[{"type":"c","name":data[i]["name"],"isVisible":1},{"type":"n","name":data[i]["name"],"isVisible":1}];
          //   let items=data[i]["items"];
          //   for(let j=0;j<items.length;j++){
          //     column.push({"type":"d","name":items[j]["name"],"isVisible":items[j]["isVisible"]});
          //   }
          //   row.items=column;
          let row = data[i];
          this.soip.push(row);
        }
        this.setSoip();
        console.log("soip:" + JSON.stringify(this.soip));
      }
    },
    //获取统计模块参数
    async getPsDataSetting() {
      //this.census=[];
      let homeVue = this;
      if (
        homeVue.$store.state.psId != undefined &&
        homeVue.$store.state.psId != ""
      ) {
        this.psId = homeVue.$store.state.psId;
      }
      const { data: ret } = await this.$http.get(
        "/service-psinfo/psdata/getPsTotalIsVisible?psId=" + this.psId,
        {}
      );

      if (ret.status == 200) {
        this.census = ret.data;
        for (let i = 0; i < this.census.length; i++) {
          let itmes = this.census[i]["items"];
          for (let j = 0; j < itmes.length; j++) {
            if (itmes[j]["isVisible"] == 1) {
              itmes[j]["active"] = "active";
              break;
            }
          }
        }
        console.log("census:" + JSON.stringify(this.census));

        setTimeout(() => {
          $(".card .card-body .census-list").each(function() {
            $(this).carousel("pause");
            let inner = $(this).children(".carousel-inner");
            inner.children(".carousel-item").removeClass("active");
            inner
              .children(".carousel-item")
              .eq(0)
              .addClass("active");
            $(this).carousel();
            //alert($(this).attr("id"))
          });
        }, 3000);

        this.$forceUpdate();
        this.setCensus();
      } else {
        swal(ret.msg, this.$t("master.goBack"), "warning");
      }

      // let homeVue=this;
      // this.$http.get('/service-psinfo/psdata/getPsTotalIsVisible?psId=2222169P3FN9KH11',{}).then(function(ret){
      //     let data=ret.data;
      //     if(data.status==200){
      //       homeVue.census=data.data;
      //       console.log(JSON.stringify(homeVue.census));
      //     }
      //     else{

      //       swal(data.msg, "请返回重试", "warning");
      //     }

      // }).catch(function(){

      // });
    },
    //获取统计模块数据
    getPsData() {
      let homeVue = this;
      if (
        homeVue.$store.state.psId != undefined &&
        homeVue.$store.state.psId != ""
      ) {
        this.psId = homeVue.$store.state.psId;
      }
      this.$http
        .get("/service-psinfo/psdata/getPsTotalData?psId=" + this.psId, {})
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            //data = {"data":{"psInfo":{"psId":"EMSS2130007C662","psName":"自然电力","psCapacity":26.88,"psTypeId":1,"psAddress":null,"psTimezone":2,"longitude":null,"psBuilddate":"2021-11-21 00:00:00","cityId":"211208A3PBS1AFW0","latitude":null},"pvDcdc":{"dayTotal":1111854.0,"monthTotal":1111854.0,"yearTotal":1111854.0,"total":1111854.0},"gridPcs":{"dayInTotal":1111854.0,"dayOutTotal":1111854.0,"monthInTotal":1111854.0,"monthOutTotal":1111854.0},"alarm":{"failureNum":0,"offlineNum":0,"stopNum":0},"co2":{"dayCo2":1111854.0,"monthCo2":1111854.0,"yearCo2":1111854.0,"totalCo2":1111854.0},"price":null,"power":{"dcdcPac":1111854.0,"pcsPac":1111854.0},"generating":null,"profit":{"dayProfit":0.0,"monthProfit":0.0,"yearProfit":0.0,"totalProfit":0.0},"weather":null}}
            homeVue.plant.plantType = data.data["psInfo"]["psTypeId"];
            homeVue.plant.plantCode = data.data["psInfo"]["psId"];
            homeVue.censusData["alarm"] = data.data["alarm"];
            data.data["alarm"] != null
              ? data.data["alarm"]
              : homeVue.censusData["alarm"];
            homeVue.censusData["co2"] =
              data.data["co2"] != null
                ? data.data["co2"]
                : homeVue.censusData["co2"];
            homeVue.censusData["pvDcdc"] =
              data.data["pvDcdc"] != null
                ? data.data["pvDcdc"]
                : homeVue.censusData["pvDcdc"];
            homeVue.censusData["power"] =
              data.data["power"] != null
                ? data.data["power"]
                : homeVue.censusData["power"];
            homeVue.censusData["gridPcs"] =
              data.data["gridPcs"] != null
                ? data.data["gridPcs"]
                : homeVue.censusData["gridPcs"];
            homeVue.censusData = data.data;
            homeVue.$forceUpdate();
            console.log(JSON.stringify(homeVue.censusData));

            setTimeout(() => {
              $(".card .card-body .census-list").each(function() {
                $(this).carousel("pause");
                let inner = $(this).children(".carousel-inner");
                inner.children(".carousel-item").removeClass("active");
                inner
                  .children(".carousel-item")
                  .eq(0)
                  .addClass("active");
                $(this).carousel();
                //alert($(this).attr("id"))
              });
            }, 3000);
          } else {
            swal(
              homeVue.$t("interfaceMsg." + data.status),
              this.$t("master.goBack"),
              "warning"
            );
          }
        })
        .catch(function() {});
    },
    getPsSoipDatas() {
      let homeVue = this;
      if (
        homeVue.$store.state.psId != undefined &&
        homeVue.$store.state.psId != ""
      ) {
        this.psId = homeVue.$store.state.psId;
      }
      this.$http
        .get("/service-psinfo/psdata/getPsSoipDatas?psId=" + this.psId, {})
        .then(function(ret) {
          let data = ret.data;
          console.log(data.data);
          if (data.status == 200) {
            homeVue.soipData = data.data;
            // homeVue.soipData={"storage":{"soc":0,"bcp":-10,"discharge":0,"devicePower":111},"outPut":{"pac":10,"devicePower":111},"inPut":{"pac":10,"dayTotal":0},"pGrid":{"pac":null,"inOutStatus":0}}
            console.log("homeVue.soipData1111", homeVue.soipData);
            var tempStates;
            //系统状态根据电站类型  1.自家消费   2.过积载
            if (homeVue.$store.state.psTypeId == 1) {
            tempStates= homeVue.soipData.pcs.pac  || 0
             homeVue.soipData.pGrid.inOutStatus=tempStates>0?1:(tempStates<0?'-1':0)
            } else if (homeVue.$store.state.psTypeId == 2) {
             tempStates = homeVue.soipData.pGrid.pac || homeVue.soipData.pcs.pac || 0;
             homeVue.soipData.pGrid.inOutStatus=tempStates>0?1:(tempStates<0?'-1':0)
            }
            //homeVue.soipData["storage"]["bv"]="123";
           
          } else {
            swal(data.msg, this.$t("master.goBack"), "warning");
          }
        })
        .catch(function() {});
    },
    /**获取电站下所有分组后的设备信息 */
    getDeviceGroup() {
      let homeVue = this;
      if (
        homeVue.$store.state.psId != undefined &&
        homeVue.$store.state.psId != ""
      ) {
        this.psId = homeVue.$store.state.psId;
      }
      this.$http
        .get("/service-psinfo/psinfo/getDeviceGroupById?psId=" + this.psId, {})
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            homeVue.deviceGroup = data.data;
            //排序dcdc>蓄电池>pcs>空调>电表  
            var sort=[2,3,1,4,5]
            var tempValue=[];
            $(sort).each(function(i,val){
                $(homeVue.deviceGroup).each(function(x,deviceType){
                    if(val==deviceType.deviceTypeId){
                        tempValue.push(deviceType)
                    }
                })
            })
            homeVue.deviceGroup=tempValue;
            
            homeVue.deviceTypeIcon = {};
            homeVue.deviceTypeColor = {};
            for (let i = 0; i < homeVue.deviceGroup.length; i++) {
              let devices = homeVue.deviceGroup[i]["devcies"];
              for (let j = 0; j < devices.length; j++) {
                let deviceTypeId = homeVue.deviceGroup[i]["deviceTypeId"];
                homeVue.deviceTypeIcon[deviceTypeId] =
                  homeVue.deviceTypeIcon01[deviceTypeId];
                /**设备组中状态如不是正常状态，设置为报警状态并结果循环 */
                if (devices[j]["deviceFlag"] == 0) {
                  //正常
                  homeVue.deviceTypeColor[deviceTypeId] = " text-success-55";
                } else if (devices[j]["deviceFlag"] == 1) {
                  //离线
                  homeVue.deviceTypeColor[deviceTypeId] = " text-warning-75";
                } else if (devices[j]["deviceFlag"] == 3) {
                  //停机
                  homeVue.deviceTypeIcon[deviceTypeId] =
                    homeVue.deviceTypeIcon[deviceTypeId];
                } else {
                  //故障
                  homeVue.deviceTypeColor[deviceTypeId] = " text-tomato-55";
                }
              }
            }
          } else {
            swal(data.msg, this.$t("master.goBack"), "warning");
          }
        })
        .catch(function() {});
    },
    //@mouseover="a1" @mouseleave="a2"
    seover(index) {
      this.active = index;
      //$event.currentTarget.className = "card-item active";
    },
    seleave(index) {
      this.active = index;
    },
    // 第一次点击统计模块设置时，添加拖动效果
    openDragger() {
      if (this.bindShow) {
        swal("电站绑定", "请绑定电站或联系管理员授权电站", "warning");
        return false;
      }
      $("#ps_st1").modal("show");

      $(".census-list").carousel("pause");
      if (this.dragger) {
        this.dragger = false;

        tableDragger(document.querySelector("#default-table"));

        tableDragger(document.querySelector("#only-bodytable"));
      }
    },

    openDragger2() {
      if (this.bindShow) {
        swal("电站绑定", "请绑定电站或联系管理员授权电站", "warning");
        return false;
      }
      $("#ps_st2").modal("show");
      $(".census-list").carousel("pause");
      if (this.dragger) {
        this.dragger = false;

        tableDragger(document.querySelector("#default-table"));

        tableDragger(document.querySelector("#only-bodytable"));
      }
    },
    //去除小数点后多余的0
    zeroHandle(old){
         old = old.toFixed(2);
         var newstr=old;
         var leng = old.length-old.indexOf(".")-1
         if(old.indexOf(".")>-1){
            for(var i=leng;i>0;i--){
                 if(newstr.lastIndexOf("0")>-1 && newstr.substr(newstr.length-1,1)==0){
                     var k = newstr.lastIndexOf("0");
                     //如果小数点后只有一个0 去掉小数点
                     if(newstr.charAt(k-1)=="."){
                         return  newstr.substring(0,k-1);
                     }else{
                     //否则 去掉一个0
                         newstr=newstr.substring(0,k);
                     }
                 }else{
                 //如果末尾没有0
                     return newstr;
                 }
             }
         }
         return old;
    },
    getNowTime() {
      const yy = new Date().getFullYear()
      const MM = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
      const dd = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
      const HH = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
      const mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      return yy + '/' + MM + '/' + dd + ' ' + HH + '：' + mm + '：' + ss
    }
  }
};
</script>

<style lang="less" scoped>
svg {
  fill: currentColor;
}
</style>
