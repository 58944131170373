<template>
  <div>
    <canvas id="myCanvas" :height="height" :width="width"></canvas>
    <canvas id="myCanvas2" :height="height" :width="width"></canvas>
    <canvas id="myCanvasText" :height="height" :width="width"></canvas>

    <!-- <img id="tulip" src="../../assets/images/canvas/BG-LINE.PNG" /> -->
  </div>
</template>
<script>
export default {
  props: ["height", "width", "dataResult", "psTypeId"],
  data() {
    return {
      imgTop: {
        url: "../../assets/images/canvas/top.svg",
        x: 0,
        y: 0,
        width: 130,
        height: 130,
        text: [
          {
            marginX: 105,
            marginY: 15,
            backgroudColor: "rgba(236,190,67,0.7)",
            fontColor: "rgba(236,190,67,0.7)",
            text: this.$t("canvas.overname") + " (kW)",
            value: this.dataResult.inPut.pac
          }
        ]
      },
      imgLeft: {
        url: "../../assets/images/canvas/left.svg",
        x: 0,
        y: 0,
        centerX: 0,
        centerY: 0,
        width: 130,
        height: 127,
        text: [
          {
            marginX: 5,
            marginY: 140,
            backgroudColor: "rgba(60,158,175,0.72)",
            fontColor: "rgba(60,158,175,0.72)",
            color: "red",
            text: this.$t("canvas.power") + " (kW)",
            value: this.dataResult.outPut.pac
          }
        ]
      },
      imgBottom: {
        url: "../../assets/images/canvas/bottom.svg",
        x: 0,
        y: 0,
        centerX: 0,
        centerY: 0,
        width: 130,
        height: 120,
        text: [
          {
            marginX: -26,
            marginY: 130,
            backgroudColor: "rgba(226,114,94,0.72)",
            fontColor: "rgba(226,114,94,0.72)",
            color: "red",
            text: "SOC (%)",
            value: this.dataResult.storage.soc
          },
          {
            marginX: -26,
            marginY: 180,
            backgroudColor: "rgba(226,114,94,0.72)",
            fontColor: "rgba(226,114,94,0.72)",
            color: "red",
            text: this.$t("canvas.charge") + " (kW)",
            value: this.dataResult.storage.bcp
          }
        ]
      },
      imgRight: {
        url: "../../assets/images/canvas/right.svg",
        x: 0,
        y: 0,
        centerX: 0,
        centerY: 0,
        width: 130,
        height: 184,
        text: [
          {
            marginX: -90,
            marginY: 195,
            backgroudColor: "rgba(101,178,117,0.72)",
            fontColor: "rgba(101,178,117,0.72)",
            color: "red",
            text: this.$t("canvas.system") + " (kW)",
            value: this.dataResult.pGrid.pac
          }
        ]
      },
      canvas: {
        c: null,
        ctx: null,
        centerX: 0,
        centerY: 0,
        width: 0,
        height: 0,
        padding: 100
      },
      canvasData: [],
      lineArr: [],
      canvasTimer: 0
    };
  },
  mounted() {
    this.canvas.c = document.getElementById("myCanvas");
    this.canvas.ctx = this.canvas.c.getContext("2d");
    this.setImg();
    this.drawAmiteLine(this.dataResult);
    this.drawText(this.dataResult);
  },
  methods: {
    //画对角线做参考
    duijiaoxian() {
      this.canvas.ctx.beginPath();
      this.canvas.ctx.moveTo(0, 180);
      this.canvas.ctx.lineTo(800, 180);

      this.canvas.ctx.moveTo(400, 0);
      this.canvas.ctx.lineTo(400, 460);
      this.canvas.ctx.lineWidth = 1;
      this.canvas.ctx.stroke();
      this.canvas.ctx.closePath();
    },
    //var start=0;
    getWidth(pointA, pointB) {
      var xWidth = (pointA.x - pointB.x) * (pointA.x - pointB.x);
      var yWidth = (pointA.y - pointB.y) * (pointA.y - pointB.y);
      var edgeWidth = Math.sqrt(xWidth + yWidth);
      return 30 / edgeWidth;
    },
    setImg() {
      var offsetLeft=0;
      if(this.$store.state.psTypeId==3){
        offsetLeft=this.width / 4;
      }
      
      this.canvas.width = this.width;
      this.canvas.height = this.height;
      if(this.$store.state.psTypeId==3){
        this.imgTop.x = 30;
      }else{
          this.imgTop.x = this.canvas.width / 2 - this.imgTop.width / 2;
      }
     
      this.imgTop.centerX = this.imgTop.x + this.imgTop.width / 2; //, y - mBitmap.getHeight()/2
      this.imgTop.centerY = this.imgTop.y + this.imgTop.width / 2;
      this.imgRight.x = this.canvas.width - this.imgRight.width;
      this.imgRight.y =(this.canvas.height - this.canvas.padding) / 2 - this.imgRight.height / 2 - 55;
      this.imgRight.centerX = this.imgRight.x + this.imgRight.width / 2; //, y - mBitmap.getHeight()/2
      this.imgRight.centerY = this.imgRight.y + this.imgRight.width / 2 + 55;
      this.imgLeft.y = (this.canvas.height - this.canvas.padding) / 2 - this.imgRight.height / 2;
      this.imgLeft.centerX = this.imgLeft.x + this.imgLeft.width / 2; //, y - mBitmap.getHeight()/2
      this.imgLeft.centerY = this.imgLeft.y + this.imgLeft.width / 2;


       if(this.$store.state.psTypeId==3){
        this.imgBottom.x = 30
      }else{
         this.imgBottom.x = this.canvas.width / 2 - this.imgTop.width / 2;
      }

      
      this.imgBottom.y = this.canvas.height - this.imgBottom.height - this.canvas.padding;
      this.imgBottom.centerX = this.imgBottom.x + this.imgBottom.width / 2; //, y - mBitmap.getHeight()/2
      this.imgBottom.centerY = this.imgBottom.y + this.imgBottom.width / 2;
      if (this.$store.state.psTypeId == 1) {
        //直流耦合自家消费
        this.drawImg(this.imgTop);
        this.drawImg(this.imgBottom);
        this.drawImg(this.imgRight);
        this.drawImg(this.imgLeft);
        //画线
        this.drawLine(this.imgBottom, this.imgRight, "line");
        this.drawLine(this.imgLeft, this.imgBottom, "line");

       // this.drawLine(this.imgRight, this.imgLeft, "line11");
        this.drawLine(this.imgLeft, this.imgTop, "line");
        this.drawLine(this.imgTop, this.imgRight, "line");
        this.drawLine(this.imgBottom, this.imgTop, "line");
      } else if (this.$store.state.psTypeId == 2 || this.$store.state.psTypeId == 4) {
        //直流耦合过激载
        this.drawImg(this.imgBottom);
        this.drawImg(this.imgRight);
        this.drawImg(this.imgLeft);
        this.drawLine(this.imgLeft, this.imgRight, "line");
        this.drawLine(this.imgBottom, this.imgRight, "line");
        this.drawLine(this.imgLeft, this.imgBottom, "line");
        //this.drawLine(this.imgRight, this.imgLeft, "line11");
      }
      else if (this.$store.state.psTypeId == 3) {
        //削峰（AC）
       
        this.drawImg(this.imgBottom);
        this.drawImg(this.imgTop);
        this.drawImg(this.imgRight);
        this.drawLine(this.imgTop, this.imgRight, "line");
        this.drawLine(this.imgBottom, this.imgTop, "line");
        this.drawLine(this.imgRight, this.imgBottom, "line");
        //this.drawLine(this.imgRight, this.imgLeft, "line11");
      }
    },
    //图
    drawImg(imgData) {
      // debugger
      var _this = this;
      var img = new Image();
      img.onload = function() {
        _this.canvas.ctx.drawImage(
          img,
          imgData.x,
          imgData.y,
          imgData.width,
          imgData.height
        );
      };
      img.src = imgData.url;
    },
    //线
    drawLine(pointA, pointB, type) {
      var _this = this;
      _this.canvas.ctx.lineWidth = 1.5;
      _this.canvas.ctx.beginPath();
      _this.canvas.ctx.moveTo(pointA.centerX, pointA.centerY);
      if (type == "line") {
        _this.canvas.ctx.lineTo(pointB.centerX, pointB.centerY);
      } else {
        //贝塞尔曲线
        _this.canvas.ctx.quadraticCurveTo(
          _this.canvas.width / 2,
          _this.canvas.height + _this.canvas.height / 2 - 50,
          pointB.centerX,
          pointB.centerY
        );
      }
      //_this.canvas.ctx.setLineDash([6.5]);
      _this.canvas.ctx.strokeStyle = "#f3f3f3";
      _this.canvas.ctx.lineJoin = "round";
      _this.canvas.ctx.stroke();
    },
    //动画的线
    drawAmiteLine(data) {
      var _this = this;
      //******************************************** */
      //电表：测量电网功率的设备
      //负荷：买电为正
      //电网(电表)：买电为正，卖电为负
      //PCS: 放电为正，充电为负
      //蓄电池：放电为正，充电为负
      //******************************************** */
      //
      if (this.$store.state.psTypeId == 1) {
        //直流耦合自家消费
        _this.drawPsType1(data);
      } else if (this.$store.state.psTypeId == 2 || this.$store.state.psTypeId == 4) {
        //直流耦合过激载
        _this.drawPsType2(data)
      }else if (this.$store.state.psTypeId == 3) {
        //直流耦合过激载
        _this.drawPsType3(data)
      }

      _this.drawAmiate();
    },
    //直流耦合自家消费    outPut（dcdc）  inPut(负荷)  storage（BMS）  pGrid(电表)    pcs(逆变器)
    drawPsType1(data){
     // data={"storage":{"soc":3.1,"bcp":-4.33,"discharge":39.99,"devicePower":1290},"outPut":{"pac":7.8,"devicePower":350,"dayPVTotal":839},"inPut":{"pac":0,"dayTotal":875},"pGrid":{"pac":null,"inOutStatus":0,"dayGridEngery":null,"daySendEngery":null},"pcs":{"pac":0,"dayOutTotal":875,"dayInTotal":0}}
       var tempPcs=data.pcs.pac==null?0:data.pcs.pac
        data.pcs.pac=tempPcs
      var _this=this;
        //光伏到储能    光伏给电池充电   1.DCDC > 0 &&  BMS < 0  (并网离网条件相同)
        if ( data.outPut.pac > 0  && data.storage.bcp < 0) {
          var line = {
            a: _this.imgLeft,
            b: _this.imgBottom,
            type: "line",
            width: _this.getWidth(_this.imgLeft, _this.imgBottom),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }

        //光伏到负荷   光伏优先供给负荷  1并网  DCDC>0 && PCS>0 && 负荷>0     2.离网 dcdc >0 (无电站状态值未做判断)
        if (data.outPut.pac > 0 &&  data.pcs.pac > 0) {
          var line = {
            a: _this.imgLeft,
            b: _this.imgTop,
            type: "line",
            width: _this.getWidth(_this.imgLeft, _this.imgTop),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }

        //储能到负荷       
        //1.BMS放电且电网买电   BMS(储能)>0  &&  pGrid(电表)>0   &&  inPut(负荷)>0    
        //2.BMS放电且电网卖电   BMS(储能)>0  &&  pGrid(电表)<0  &&  inPut(负荷)>0    &&    BMS(储能)!=pGrid(电表)
        //3.进入BCP    离网状态   该状态暂时没有   BMS(储能)>0   &&  inPut(负荷)>0
        if ((data.storage.bcp > 0 && data.pGrid.pac>0 && data.inPut.pac>0 )  || (data.storage.bcp > 0 && data.pGrid.pac<0 && Math.abs(data.storage.bcp)!=Math.abs(data.pGrid.pac))) {
          var line = {
            a: _this.imgBottom,
            b: _this.imgTop,
            type: "line",
            width: _this.getWidth(_this.imgBottom, _this.imgTop),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }
        var tempPac=data.pGrid.pac==null?0:data.pGrid.pac
        data.pGrid.pac=tempPac
        //电网到负荷   
        //1.系统供电BMS放电       pcs(逆变器) >0  &&  pGrid(电表)>0  
        //2.系统供电BMS待机       pcs(逆变器) =0  &&  pGrid(电表)>0  
        //3.系统供电BMS和负载     pcs(逆变器) <0  &&  pGrid(电表)>0  &&  pGrid(电表)-pcs(逆变器)>0  
        if ((data.pGrid.pac > 0 &&  data.pcs.pac > 0) || (data.pGrid.pac > 0 &&  data.pcs.pac == 0) ||  (data.pGrid.pac > 0 && data.pcs.pac < 0 && Math.abs(data.pGrid.pac)-Math.abs(data.pcs.pac)>0) ) {
          var line = {
            a: _this.imgRight,
            b: _this.imgTop,
            type: "line",
            width: _this.getWidth(_this.imgRight, _this.imgTop),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }
        //系统向电网放电    
        //1.pcs(逆变器) >0 &&  pGrid(电表)>=0  
        //2.pcs(逆变器) >0 &&  pGrid(电表)<0  
        if (data.pcs.pac > 0) {
          var line = {
            a: _this.imgBottom,
            b: _this.imgRight,
            type: "line",
            width: _this.getWidth(_this.imgBottom, _this.imgRight),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }
        //电网到系统
        //1.pcs(逆变器) < 0 &&  pGrid(电表)>0 
        if (data.pcs.pac < 0 && data.pGrid.pac > 0) {
          var line = {
            a: _this.imgRight,
            b: _this.imgBottom,
            type: "line",
            width: _this.getWidth(_this.imgRight, _this.imgBottom),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }
    },
    //直流耦合过激载
    drawPsType2(data){
          var _this=this;
        //光伏到储能    1.DCDC>0 && BMS<0
        if (data.outPut.pac >0 && data.storage.bcp<0) {
          var line = {
            a: _this.imgLeft,
            b: _this.imgBottom,
            type: "line",
            width: _this.getWidth(_this.imgLeft, _this.imgBottom),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }

        //光伏到电网    1.DCDC>0
        if (data.outPut.pac > 0 ) {
          var line = {
            a: _this.imgLeft,
            b: _this.imgRight,
            type: "line",
            width: _this.getWidth(_this.imgLeft, _this.imgRight),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }
 
        //储能到电网   1.BMS>0
        if (data.storage.bcp > 0) {
          var line = {
            a: _this.imgBottom,
            b: _this.imgRight,
            type: "line",
            width: _this.getWidth(_this.imgBottom, _this.imgRight),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }
    },
      //削峰（AC）
    drawPsType3(data){
          var _this=this;
        //蓄电池放电    1.pcs(逆变器)>0 
        if (data.pcs.pac > 0) {
          var line = {
            a: _this.imgBottom,
            b: _this.imgTop,
            type: "line",
            width: _this.getWidth(_this.imgBottom, _this.imgTop),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }

        //蓄电池放电    1.pcs(逆变器)>0 
        if (data.pcs.pac > 0 ) {
          var line = {
            a: _this.imgBottom,
            b: _this.imgRight,
            type: "line",
            width: _this.getWidth(_this.imgBottom, _this.imgRight),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }
 
        //蓄电池充电    1.pcs(逆变器)<0 
        if (data.pcs.pac < 0 ) {
          var line = {
            a: _this.imgRight,
            b: _this.imgBottom,
            type: "line",
            width: _this.getWidth(_this.imgRight, _this.imgBottom),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }



          //系统供电    1.pcs(逆变器)>0 || (pcs<0 && |Meter|>|PCS|)
        if (data.pcs.pac > 0 || (data.pcs.pac <= 0 && Math.abs(data.pGrid.pac)>Math.abs(data.pcs.pac))) {
          var line = {
            a: _this.imgRight,
            b: _this.imgTop,
            type: "line",
            width: _this.getWidth(_this.imgRight, _this.imgTop),
            start: 0
          };
          _this.lineArr.push(line);
          var start = 0;
        }
    },
    //动画的线添加
    drawAmiate() {
      var grd = null;
      var _this = this;
      if (_this.canvasTimer != 0) {
        cancelAnimationFrame(_this.canvasTimer);
      }

      var offCanvas = document.getElementById("myCanvas2"); //离屏canvas 通过代码创建出来的
      offCanvas.width = _this.canvas.width;
      offCanvas.height = _this.canvas.height;
      var offContext = offCanvas.getContext("2d");
      var width = 0;
      function auto() {
        offContext.clearRect(0, 0, 3000, 2000);

        $(_this.lineArr).each(function(key, line) {
          offContext.beginPath();
          //offContext.setLineDash([6.5,2]);
          offContext.moveTo(line.a.centerX, line.a.centerY);
          if (line.type == "line") {
            offContext.lineTo(line.b.centerX, line.b.centerY);
          } else {
            //贝塞尔曲线
            offContext.quadraticCurveTo(
              _this.canvas.width / 2,
              _this.canvas.height + _this.canvas.height / 2 - 50,
              line.b.centerX,
              line.b.centerY
            );
          }

          grd = offContext.createLinearGradient(
            line.a.centerX,
            line.a.centerY,
            line.b.centerX,
            line.b.centerY
          );
          grd.addColorStop(0, "rgba(255, 255, 255, 0)");
          grd.addColorStop(line.start, "rgba(255, 255, 255, 0)");
          grd.addColorStop(line.start + line.width / 2, "#05e785"); //width
          grd.addColorStop(line.start + line.width, "rgba(255, 255, 255, 0)");
          grd.addColorStop(1, "rgba(255, 255, 255, 0)");
          offContext.strokeStyle = grd;
          offContext.lineWidth = 4.5;
          offContext.lineJoin = "round";
          offContext.stroke();
          offContext.closePath();

          if (line.start + line.width + 0.002 >= 1) {
            line.start = 0;
          } else {
            line.start = line.start + 0.002;
          }
        });

        _this.canvasTimer = requestAnimationFrame(auto);
      }
      auto();
    },
    //添加文字
    drawText(data) {
      var _this = this;
      var textCanvas = document.getElementById("myCanvasText"); //离屏canvas 通过代码创建出来的
      textCanvas.width = _this.canvas.width;
      textCanvas.height = _this.canvas.height;
      var textContext = textCanvas.getContext("2d");
      textContext.clearRect(0, 0, 1000, 460);
      //画底部数据
      _this.imgBottom.text[0].value = data.storage.soc == null ? "-" : data.storage.soc;
      _this.imgBottom.text[1].text = data.storage.bcp < 0 ? this.$t("canvas.charge") + " (kW) " : this.$t("canvas.discharge") + " (kW) ";
      _this.imgBottom.text[1].value = data.storage.bcp == null || data.storage.bcp == "-" ? "-" : Math.abs(data.storage.bcp);
      $(_this.imgBottom.text).each(function(i, val) {
        _this.fillRoundRect(textContext, _this.imgBottom, val);
      });

      if (this.$store.state.psTypeId == 1 || this.$store.state.psTypeId == 3) {
        //直流耦合自家消费
        _this.imgTop.text[0].value = data.inPut.pac == null ? "-" : data.inPut.pac;
        _this.fillRoundRect(textContext, _this.imgTop, _this.imgTop.text[0]);
        // _this.imgLeft.text[0].marginY = -35;
        // _this.imgRight.text[0].marginY = 110;
        //电网取电表功率
         _this.imgRight.text[0].text=_this.$t("canvas.system") + " (kW)"
         _this.imgRight.text[0].value = data.pcs.pac == null ? "-" : data.pcs.pac;
      } else if (this.$store.state.psTypeId == 2 || this.$store.state.psTypeId == 4) {
        //直流耦合过激载
        // _this.imgLeft.text[0].marginY = -35;
        // _this.imgRight.text[0].marginY = 60;
        //电网取电表功率（电网侧取PCS发电功率）
         _this.imgRight.text[0].text=_this.$t("canvas.system") + " (kW)"
         var tempPac=data.pGrid.pac || data.pcs.pac
         _this.imgRight.text[0].value = tempPac == null ? "-" : tempPac;
      }
      _this.fillRoundRect(textContext, _this.imgRight, _this.imgRight.text[0]);


       if (this.$store.state.psTypeId == 1 || this.$store.state.psTypeId == 2 || this.$store.state.psTypeId == 4) {
        _this.imgLeft.text[0].value = data.outPut.pac == null ? "-" : data.outPut.pac;
        _this.fillRoundRect(textContext, _this.imgLeft, _this.imgLeft.text[0]);
      }

      
    },
    /**该方法用来绘制一个有填充色的圆角矩形
     *@param cxt:canvas的上下文环境
     *@param x:左上角x轴坐标
     *@param y:左上角y轴坐标
     *@param width:矩形的宽度
     *@param height:矩形的高度
     *@param radius:圆的半径
     *@param fillColor:填充颜色
     **/
    fillRoundRect(ctx, img, text) {
      var _this = this;
      var engWidth=0;
      if(text.text=="Customer Consumption (kW)"){
          engWidth=120;
      }
      var width = 210+engWidth;
      var height = 35;
      var radius = 7;
      //圆的直径必然要小于矩形的宽高
      if (2 * radius > width || 2 * radius > height) {
        return false;
      }
      var x = img.x + text.marginX;
      var y = img.y + text.marginY;
      ctx.save();
      ctx.translate(x, y);
      //绘制圆角矩形的各个边
      _this.drawRoundRectPath(ctx, width, height, radius);
      ctx.fillStyle = text.backgroudColor || "#000"; //若是给定了值就用给定的值否则给予默认值
      ctx.fill();
      ctx.restore();

      var img = new Image();
      img.src =
        // 绘制文字
        ctx.save();
      ctx.font = "14px bold Arial";
      ctx.fillStyle = "#fff";
      // ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      // console.log("img.text.text:" + text.text + "x：" + x + "y:" + y);
      ctx.fillText(text.text, x + 8, y + 18);

      // 绘制文字
      ctx.font = "normal bold 30px Arial,sans-serif";
      ctx.fillStyle = "#fff";
      ctx.shadowColor = text.fontColor;
      ctx.shadowBlur = "15";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      try {
        text.value=text.value.toFixed(1);
      }
      catch(err){

      }
     
      ctx.fillText(text.value, x + 160+engWidth, y + 20);
      ctx.restore();
    },
    drawRoundRectPath(ctx, width, height, radius) {
      var _this = this;
      ctx.beginPath(0);
      //从右下角顺时针绘制，弧度从0到1/2PI
      ctx.arc(width - radius, height - radius, radius, 0, Math.PI / 2);

      //矩形下边线
      ctx.lineTo(radius, height);

      //左下角圆弧，弧度从1/2PI到PI
      ctx.arc(radius, height - radius, radius, Math.PI / 2, Math.PI);

      //矩形左边线
      ctx.lineTo(0, radius);

      //左上角圆弧，弧度从PI到3/2PI
      ctx.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2);

      //上边线
      ctx.lineTo(width - radius, 0);

      //右上角圆弧
      ctx.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2);

      //右边线
      ctx.lineTo(width, height - radius);
      ctx.closePath();
    }
  },
  watch: {
    width(newV, oldV) {
      this.canvas.ctx.clearRect(0, 0, newV, 460); // 清空画布
    },
    dataResult: {
      handler(dataResult) {
        //根据数据动画效果
        this.lineArr = [];
        this.drawAmiteLine(dataResult);
        this.drawText(dataResult);
      }
    },
    "$store.state.psTypeId": function() {
      //电站类型变化的时候重新画图
      //this.setImg();
    }
  }
};
</script>
<style scoped>
#myCanvas,
#myCanvas2,
#myCanvasText {
  position: absolute;
  left: 0px;
  top: 0px;
  border: none;
  cursor: pointer;
}
</style>
